import Hero from "../components/hero";
import SeoMeta from "../components/seo-meta";

export default function Custom404() {
  return (
    <>
      <SeoMeta />
      <div className="container">
        <Hero title="404" description="This page could not be found." />
      </div>
    </>
  );
}
