import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

import Button from "../button";

import styles from "./hero.module.scss";

const Hero = ({
  title,
  subtitle,
  description,
  cta,
  ctaUrl,
  ctaLabel,
  image,
  lightWeightFont = false,
  fullWidthTitle = false
}) => {
  return (
    <div className={styles.Hero}>
      <div
        className={clsx(
          styles.Content,
          fullWidthTitle && styles.FullWidthTitle
        )}
      >
        <div className={styles.Title}>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>

        <p
          className={clsx(
            styles.Description,
            lightWeightFont && styles.DescriptionLightFont
          )}
        >
          {description}
        </p>

        {ctaUrl && ctaLabel && (
          <div className={styles.CTA}>
            <Link href={ctaUrl} passHref>
              <Button outline rounded small>
                {ctaLabel}
              </Button>
            </Link>
          </div>
        )}

        {cta && <div className={styles.CTA}>{cta}</div>}
      </div>
      {image && (
        <div className={styles.Image}>
          <Image src={image.url} alt={image.description} layout="fill" />
        </div>
      )}
    </div>
  );
};

export default Hero;
